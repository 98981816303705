import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import Routes from './Routes.js'
import { SEORouter } from './SEORouter'
import { isSEOPath } from './services/urlStateParser'
import './Vendor.js'
import './App.scss'
import {
  fetchSearchSession,
  checkLoginStatus
} from './redux/actions'
import { connect } from 'react-redux'
import { setupTracking } from './services/algolia'
import PropTypes from 'prop-types'
import RedirectionNotificationPage from './components/RedirectionNotificationPage/RedirectionNotificationPage'
import 'react-dates/initialize'
import { ErrorProvider } from './services/rollbar'
class App extends React.Component {
  componentDidMount () {
    this.props.checkLoginStatus(this.props.isLoggedIn)
    this.props.fetchSearchSession()

    if (this.props.personalizationKey) {
      setupTracking(this.props.personalizationKey)
    }
  }

  render () {
    if (this.props.isLoggedIn || this.props.isDoneCheckingLoginStatus) {
      if (this.props.redirectionURL) {
        window.location.href = this.props.redirectionURL
        return <RedirectionNotificationPage />
      } else if (isSEOPath(window.location.pathname)) {
        return (
          <ErrorProvider>
            <SEORouter>
              <Routes {...this.props} />
            </SEORouter>
          </ErrorProvider>
        )
      } else {
        return (
          <ErrorProvider>
            <Router>
              <Routes {...this.props} />
            </Router>
          </ErrorProvider>
        )
      }
    } else {
      return (<div className='overlay'>Loading... Please wait.</div>)
    }
  }
}

const mapStateToProps = state => {
  return {
    requestedIds: state.app.requestedIds || [],
    redirectionURL: state.app.redirectionURL,
    personalizationKey: state.personalizationKey,
    isLoggedIn: state.app.isLoggedIn,
    isDoneCheckingLoginStatus: state.app.isDoneCheckingLoginStatus
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchSearchSession: payload => dispatch(fetchSearchSession(payload)),
    checkLoginStatus: payload => dispatch(checkLoginStatus(payload))
  }
}

App.propTypes = {
  fetchSearchSession: PropTypes.func,
  redirectionURL: PropTypes.string,
  checkLoginStatus: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
