import React from 'react'
import i18n from 'i18next'
import { Router } from 'react-router'
import { createBrowserHistory as createHistory } from 'history'
import PropTypes from 'prop-types'

function filterOutLocaleParam (params) {
  return params.filter((param) => param && param !== '' && !param.startsWith('locale=') && !param.endsWith('='))
}
function queryStringWithoutLocaleParam (params) {
  return filterOutLocaleParam(params).sort().join('&')
}

function urlMatches (url, otherUrl) {
  if (url === otherUrl) {
    return true
  }

  const urlComponents = url.split('?')
  const urlPath = urlComponents[0]
  const urlQueryString = urlComponents[1] || ''

  const otherUrlComponents = otherUrl.split('?')
  const otherUrlPath = otherUrlComponents[0]
  const otherUrlQueryString = otherUrlComponents[1] || ''

  if (urlPath !== otherUrlPath) {
    return false
  }

  const urlParams = queryStringWithoutLocaleParam(urlQueryString.split('&'))
  const otherUrlParams = queryStringWithoutLocaleParam(otherUrlQueryString.split('&'))

  return (urlParams === otherUrlParams)
}

function changeToSeoFriendlyUrl (path, seoUrlMap) {
  const pathname = typeof path === 'string' ? path.split('?')[0] : path.pathname
  let search = (typeof path === 'string' ? path.split('?')[1] : path.search) || ''

  const searchHasQuestionmark = search.startsWith('?')
  search = search.replace(/^\?/, '')

  if (pathname) {
    let origParams = search.split('&')
    const localeParam = origParams.filter((param) => param.startsWith('locale='))[0] || ''

    let language = localeParam && (localeParam === 'locale=en' ? 'en' : 'de')
    language = language || i18n.language

    origParams = queryStringWithoutLocaleParam(origParams)

    const seoUrl = seoUrlMap.filter((url) => {
      const urlComponents = url.original_path.split('?')
      const urlPath = urlComponents[0]

      if (urlPath !== pathname) {
        return false
      }

      const queryString = urlComponents[1] || ''

      let params = queryString.split('&')
      params = queryStringWithoutLocaleParam(params)
      return (params === origParams)
    })[0]

    if (seoUrl) {
      if (typeof path === 'string') {
        path = `${seoUrl[`seo_path_${language}`]}?${localeParam}`
      } else {
        path.pathname = seoUrl[`seo_path_${language}`]
        path.search = `${searchHasQuestionmark ? '?' : ''}${localeParam}`
      }
    }

    return path
  }
}

class SEORouter extends React.Component {
  constructor (props) {
    super(props)

    this.history = createHistory(this.props)

    const seoUrlMap = JSON.parse(document.getElementById('seo-url-map').getAttribute('data'))
    this.seoUrlMap = seoUrlMap

    const origPush = this.history.push
    const origReplace = this.history.replace

    this.history.push = function (path, state) {
      origPush(changeToSeoFriendlyUrl(path, seoUrlMap), state)
    }

    this.history.replace = function (path, state) {
      origReplace(changeToSeoFriendlyUrl(path, seoUrlMap), state)
    }
  }

  translateSeoUrlToOriginalPath () {
    const currentPath = `${this.history.location.pathname}${this.history.location.search}`

    const seoUrl = this.seoUrlMap.filter((url) => {
      return urlMatches(currentPath, url.seo_path_de) || urlMatches(currentPath, url.seo_path_en)
    })[0]
    if (seoUrl) {
      let locale

      if (urlMatches(currentPath, seoUrl.seo_path_en)) {
        locale = 'en'
      } else {
        locale = 'de'
      }

      const urlComponents = seoUrl.original_path.split('?')
      const pathname = urlComponents[0]
      const queryString = urlComponents[1]

      const params = filterOutLocaleParam((queryString || '').split('&'))
      params.push(`locale=${locale}`)

      this.history.location.pathname = pathname
      this.history.location.search = `?${params.join('&')}`
    }
  }

  render () {
    this.translateSeoUrlToOriginalPath()

    return <Router history={this.history} children={this.props.children} />
  }
}

SEORouter.propTypes = {
  basename: PropTypes.string,
  children: PropTypes.node,
  forceRefresh: PropTypes.bool,
  getUserConfirmation: PropTypes.func,
  keyLength: PropTypes.number
}

class MatchNotFound extends React.Component {
  constructor (props) {
    super(props)

    const seoUrlMap = JSON.parse(document.getElementById('seo-url-map').getAttribute('data'))
    this.seoUrlMap = seoUrlMap
  }

  render () {
    const currentPath = `${this.props.history.location.pathname}${this.props.history.location.search}`

    const seoUrl = this.seoUrlMap.filter((url) => {
      return urlMatches(currentPath, url.seo_path_de) || urlMatches(currentPath, url.seo_path_en)
    })[0]

    if (!seoUrl) {
      this.props.history.replace({ pathname: '/', search: '' })
    }

    return (
      <div>
        <h2>No match found</h2>
      </div>
    )
  }
}

export { SEORouter, MatchNotFound }
