import queryString from 'qs'
import { startsWith } from 'lodash'
import { useLocation, useHistory } from 'react-router-dom'

const cities = {
  de: {
    berlin: 'ChIJAVkDPzdOqEcRcDteW0YgIQQ',
    frankfurt: 'ChIJxZZwR28JvUcRAMawKVBDIgQ',
    koeln: 'ChIJ5S-raZElv0cR8HcqSvxgJwQ',
    bonn: 'ChIJSdrLfJ_hvkcRF5ZcaMb424Y',
    mannheim: 'ChIJRT-OUSTMl0cRh-YGpHr-5LE',
    hannover: 'ChIJhU9JTVELsEcRIEeslG2sJQQ',
    hamburg: 'ChIJuRMYfoNhsUcRoDrWe_I9JgQ',
    duesseldorf: 'ChIJB1lG8XvJuEcRsHMqSvxgJwQ',
    muenchen: 'ChIJ2V-Mo_l1nkcRfZixfUq4DAE',
    mainz: 'ChIJeRPfMy6RvUcRoBvbENXUIgQ',
    leipzig: 'ChIJcywPIBj4pkcRUvW0udKA35M',
    nuernberg: 'ChIJ0xy2ta5Xn0cRtyGMqYWvXd0',
    stuttgart: 'ChIJ04-twTTbmUcR5M-RdxzB1Xk',
    fulda: 'ChIJLbn56Uk0o0cRMKuwKVBDIgQ',
    wien: 'ChIJn8o2UZ4HbUcRRluiUYrlwv0',
    dortmund: 'ChIJEXrwv2AXuUcRUIdUMYHyJwQ',
    essen: 'ChIJOfarlrfCuEcRnSytpBHhAGo',
    kassel: 'ChIJHWx6n04_u0cRRxciWD1jhC8',
    darmstadt: 'ChIJ2c1zEWRwvUcR8MWwKVBDIgQ',
    heidelberg: 'ChIJzdzMDgXBl0cR1zokRADq5u8',
    ulm: 'ChIJ_Qs1mGlkmUcRkH3fpbdrHwQ',
    erfurt: 'ChIJcdZYg4VypEcRADYzdMGOIAQ',
    bremen: 'ChIJNePuDBAosUcRUd83-VyI6MI',
    dresden: 'ChIJqdYaECnPCUcRsP6IQsuxIQQ',
    wiesbaden: 'ChIJO4RcSdm9vUcRwWYzw4D9EQY',
    leverkusen: 'ChIJXXL7Vr8uv0cR_T55Et295Zs',
    attenkirchen: 'ChIJxaYz8NhAnkcRJtstuOjMzxU' // added for e2e test
  }
}

const suitables = {
  de: {
    aparthotel: 'aparthotel',
    ausstellung: 'exhibition', // Ausstellung
    barcamp: 'meeting', // Tagung
    betriebsausflug: 'party', // Firmen- / Vereinsfeiern
    betriebsmeeting: 'meeting', // Tagung
    firmenevent: 'party', // Firmen- / Vereinsfeiern
    event: 'event', // Event
    fachtagung: 'meeting', // Tagung
    fishbowl: 'meeting', // Tagung
    'green-meeting': 'meeting', // Tagung
    incentive: 'incentive', // Incentive
    jubiläum: 'party', // Firmen- / Vereinsfeiern
    'kick-off': 'teambuilding', // Motivation/ Teambuilding
    konferenz: 'congress', // Kongress
    kongress: 'congress', // Kongress
    kundenevent: 'customer_event', // Kundenevent
    meeting: 'meeting', // Tagung
    messeveranstaltung: 'exhibition', // Ausstellung
    teamevent: 'teambuilding', // Motivation/ Teambuilding
    'office-space': 'meeting', // Tagung
    rahmenprogramme: 'teambuilding', // Motivation/ Teambuilding
    'roadshow-event': 'customer_event', // Kundenevent
    seminar: 'seminar', // Seminar
    'shared-office': 'meeting', // Tagung
    symposium: 'congress', // Kongress
    tagung: 'meeting', // Tagung
    teambuilding: 'teambuilding', // Motivation/ Teambuilding
    weihnachtsfeier: 'party', // Firmen- / Vereinsfeiern
    workshop: 'meeting', // Tagung
    'world-cafe': 'meeting' // Tagung
  }
}

function constructEachRoute (locale) {
  const result = []
  for (const k in suitables[locale]) {
    result.push({
      path: `/${k}`,
      searchProps: { suitable: suitables[locale][k] }
    })

    for (const l in cities[locale]) {
      result.push({
        path: `/${k}-${l}`,
        searchProps: {
          suitable: suitables[locale][k],
          locationId: cities[locale][l]
        }
      })
    }
  }
  for (const l in cities[locale]) {
    result.push({
      path: `/${l}`,
      searchProps: {
        locationId: cities[locale][l]
      }
    })
  }
  return result
}

export const eachRoute = {
  de: constructEachRoute('de')
}

export function isSEOPath (path) {
  for (const setting of eachRoute.de.values()) {
    if (startsWith(path, setting.path)) {
      return true
    }
  }
  return false
}

function extractQueryParamsFrom (location) {
  return queryString.parse(location.search, { ignoreQueryPrefix: true })
}

export function extractQueryParams (props) {
  return extractQueryParamsFrom(props.location)
}

function autoConvertNumberAndBools (obj = {}) {
  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      if (obj[key] === 'true') {
        obj[key] = true
      } else if (obj[key] === 'false') {
        obj[key] = false
      } else if (!isNaN(obj[key])) {
        obj[key] = Number(obj[key])
      }
    } else if (Array.isArray(obj[key])) {
      obj[key] = obj[key].map((item) => {
        if (typeof item === 'string') {
          if (item === 'true') {
            return true
          } else if (item === 'false') {
            return false
          } else if (!isNaN(item)) {
            return Number(item)
          }
        }
        return item
      })
    } else if (typeof obj[key] === 'object') {
      obj[key] = autoConvertNumberAndBools(obj[key])
    }
  }
  return obj
}

export function useQueryParams () {
  const history = useHistory()
  const location = useLocation()

  const params = extractQueryParamsFrom(location)
  const update = (updated, pathname) =>
    history.push({
      pathname: pathname || location.pathname,
      search: queryString.stringify({ ...params, ...updated })
    })
  const updateV2 = (filters, pathname = location.pathname) => {
    // Removing filters that are empty strings
    const applyableFilters = Object.keys({ ...filters, ...params }).reduce((acc, key) => {
      if (filters[key] !== '') {
        acc[key] = filters[key]
      }
      return acc
    }, {})
    history.push({
      pathname: pathname,
      search: queryString.stringify(
        { ...applyableFilters },
        { arrayFormat: 'comma' }
      )
    })
  }
  const getParsedParams = () => {
    const parsedParams = queryString.parse(params, { comma: true })
    return autoConvertNumberAndBools(parsedParams)
  }
  const remove = (...keys) => {
    const newParams = { ...params }
    for (const key of keys) {
      delete newParams[key]
    }
    history.push({ search: queryString.stringify(newParams) })
  }
  const asArray = (field) => {
    return (params[field] || '').split(',').filter((e) => e !== '')
  }
  return {
    pathname: location.pathname,
    params,
    update,
    remove,
    asArray,
    updateV2,
    getParsedParams
  }
}
