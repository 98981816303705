import React, { Component } from 'react'
import { Col, Row } from 'reactstrap'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Logo from '../../assets/images/logo-white.png'
import HighContrastModeSwitch from '../HighContrastModeSwitch/HighContrastModeSwitch'

class Footer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentYear: new Date().getFullYear()
    }
    this.onGetListedButtonClick = this.onGetListedButtonClick.bind(this)
  }

  onCookieSettingsButtonClick () {
    const container = $('.js-cookies-eu')
    if (container) {
      $(window).scrollTop(0)
      $('body').css('overflow', 'hidden')
      container.css('display', 'block')
      $('#allowAllBtn').focus()
    }
  }

  onGetListedButtonClick () {

  }

  render () {
    const { t } = this.props
    const linkToRV = this.props.i18n.language === 'de' ? 'https://sites.miceportal.com/ratefinder_mice_portal' : 'https://sites.miceportal.com/en/ratefinder_mice_portal'
    return (
      <div style={{ position: 'relative', borderTop: '1px solid #ffffff' }}>
        <footer className='c-layout-footer c-layout-footer-6  client-theme--bg' style={{ position: 'absolute', width: '100%' }}>
          <div className='containerFluid' style={{ borderBottom: '1px solid #ffffff' }}>
            <div className='c-prefooter'>
              <div className='c-body'>
                <Row>
                  <Col className='mt-3 mb-3' xl={{ size: 2, order: 1 }} md={{ size: 4, order: 1 }} xs={{ size: 6, order: 5 }}>
                    <p className='c-address c-font-16 c-left '>
                      <img src={Logo} style={{ width: '95px', height: 'auto', marginBottom: '5px' }} alt={t('mice_portal')} />
                    </p>
                    <div className='c-left'>
                      <div className='socicon'>
                        <a href='https://www.facebook.com/miceportalgmbh' rel='noopener noreferrer' target='_blank' className='social-icon client-theme--font-color' aria-label='facebook'>
                          <i className='fab fa-facebook' />
                        </a>
                        <a href='https://twitter.com/MICE_Portal' rel='noopener noreferrer' target='_blank' className='social-icon client-theme--font-color' aria-label='twitter'>
                          <i className='fab fa-twitter' />
                        </a>
                        <a href='https://www.linkedin.com/company/mice-portal-gmbh/' rel='noopener noreferrer' target='_blank' className='social-icon client-theme--font-color' aria-label='linked in'>
                          <i className='fab fa-linkedin' />
                        </a>
                        <a href='https://www.xing.com/companies/miceportalgmbh' rel='noopener noreferrer' target='_blank' className='social-icon client-theme--font-color' aria-label='xing'>
                          <i className='fab fa-xing' />
                        </a>
                      </div>
                    </div>
                  </Col>
                  <Col className='mt-3 mb-3 invisible-mobile' xl={{ size: 2, order: 2 }} md={{ size: 4, order: 4 }} xs={{ size: 12, order: 4 }}>
                    <div className='c-content-title-1 c-title-md'>
                      <h3 className='c-title c-font-uppercase c-font-bold client-theme--font-color'>{t('footer_topic_news_to_use')}</h3>
                    </div>
                    <p>
                      <a href='https://sites.miceportal.com/mice-portal-newsletter' rel='noopener noreferrer' className='btn btn-xs c-btn-white c-btn-border-1x'>{t('newsletter')}</a>
                    </p>
                    <p className='mt-2'>
                      <a href='https://blog.miceportal.com/' rel='noopener noreferrer' className='btn btn-xs c-btn-white c-btn-border-1x'>{t('mice_blog')}</a>
                    </p>
                    <p className='mt-2'>
                      <a href='https://knowledge.miceportal.com/' target='_blank' rel='noopener noreferrer' className='btn btn-xs c-btn-white c-btn-border-1x'>{t('mice_knowledge')}</a>
                    </p>
                  </Col>
                  <Col className='mt-3 mb-3 invisible-mobile' xl={{ size: 2, order: 3 }} md={{ size: 4, order: 2 }} xs={{ size: 12, order: 1 }}>
                    <div className='c-content-title-1 c-title-md'>
                      <h3 className='c-title c-font-uppercase c-font-bold client-theme--font-color'>{t('footer_topic_for_suppliers')}</h3>
                    </div>
                    <ul className='c-links c-theme-ul client-theme--font-color'>
                      <li>
                        <a href='https://corporate.miceportal.com/event-selling' target='_blank' rel='noopener noreferrer'>{t('basic_account_product')}</a>
                      </li>
                      <li>
                        <a href='https://sites.miceportal.com/mice-products' target='_blank' rel='noopener noreferrer'>{t('pro_account_product')}</a>
                      </li>
                      <li>
                        <a href='https://corporate.miceportal.com/mice-advertisement' target='_blank' rel='noopener noreferrer'>{t('ad_product')}</a>
                      </li>
                      <li>
                        <a href={linkToRV} target='_blank' rel='noopener noreferrer'>{t('framework_contract_partner')}</a>
                      </li>
                    </ul>
                  </Col>
                  <Col className='mt-3 mb-3 invisible-mobile' xl={{ size: 2, order: 4 }} md={{ size: 4, order: 3 }} xs={{ size: 12, order: 2 }}>
                    <div className='c-content-title-1 c-title-md'>
                      <h3 className='c-title c-font-uppercase c-font-bold client-theme--font-color'>{t('footer_topic_for_companies')}</h3>
                    </div>
                    <ul className='c-links c-theme-ul client-theme--font-color'>
                      <li>
                        <a href='https://corporate.miceportal.com/mice-softwareloesung' target='_blank' rel='noopener noreferrer'>{t('mice_solution')}</a>
                      </li>
                      <li>
                        <a href='https://corporate.miceportal.com/eventservice' target='_blank' rel='noopener noreferrer'>{t('mice_event_service')}</a>
                      </li>
                    </ul>
                  </Col>
                  <Col className='mt-3 mb-3 invisible-mobile' xl={{ size: 2, order: 5, offset: 0 }} md={{ size: 4, order: 5 }} xs={{ size: 12, order: 3 }}>
                    <div className='c-content-title-1 c-title-md'>
                      <h3 className='c-title c-font-uppercase c-font-bold client-theme--font-color'>{t('footer_topic_learn_more_about_us')}</h3>
                    </div>
                    <ul className='c-links c-theme-ul client-theme--font-color'>
                      <li>
                        <a href='https://corporate.miceportal.com/team' target='_blank' rel='noopener noreferrer'>{t('mice_team')}</a>
                      </li>
                      <li>
                        <a href='https://corporate.miceportal.com/partner' target='_blank' rel='noopener noreferrer'>{t('mice_partner')}</a>
                      </li>
                      <li>
                        <a href='https://corporate.miceportal.com/karriere' target='_blank' rel='noopener noreferrer'>{t('mice_career')}</a>
                      </li>
                      <li>
                        <a href='https://corporate.miceportal.com/nachhaltigkeit' target='_blank' rel='noopener noreferrer'>{t('mice_sustainability')}</a>
                      </li>
                      <li>
                        <a href='https://corporate.miceportal.com/termine' target='_blank' rel='noopener noreferrer'>{t('mice_appointments')}</a>
                      </li>
                    </ul>
                  </Col>
                  <Col className='mt-3 mb-3' xl={{ size: 2, order: 6 }} md={{ size: 4, order: 6 }} xs={{ size: 2, order: 6 }}>
                    <div className='c-content-title-1 c-title-md'>
                      <h3 className='c-title c-font-uppercase c-font-bold client-theme--font-color'>{t('footer_topic_legal')}</h3>
                    </div>
                    <ul className='c-links c-theme-ul client-theme--font-color'>
                      <li>
                        <Link to='/terms' className='c-footer-link'>{t('terms_and_conditions')}</Link>
                      </li>
                      <li>
                        <Link to='/data-privacy' className='c-footer-link'>{t('privacy_policy')}</Link>
                      </li>
                      <li>
                        <a href={`/imprint?locale=${this.props.i18n.language}`} className='c-footer-link'>{t('imprint')}</a>
                      </li>
                      <li>
                        <HighContrastModeSwitch />
                      </li>
                      <li>
                        <Link to='#' className='c-footer-link' onClick={() => { this.onCookieSettingsButtonClick() }}>{t('cookies_settings')}</Link>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          <div className='c-postfooter c-bg-dark-2'>
            <div className='containerFluid'>
              <Row className='pt-3 pb-3'>
                <Col md={12}>
                  <p className='c-copyright text-light'>
                    <span>
                      {this.state.currentYear} © {t('mice_portal_gmbh')}
                    </span>
                    <span style={{ paddingLeft: '15px' }} className='client-theme--font-color'>
                      {t('all_rights_reserved')}.
                    </span>
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </footer>
      </div>
    )
  }
}
export default withTranslation()(Footer)
